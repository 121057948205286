<template>
  <div
    v-loading="loading"
    class="TypeManagement"
  >
    <div class="list">
      <ul>
        <li
          v-for="(item, index) in orderList"
          :key="index"
          :class="{
            active: activeIndex === index,
            dragover: dragoverIndex === index
          }"
          :draggable="isEditState ? true : false"
          @dragover.prevent="dragoverIndex = index"
          @dragend="dragoverIndex = -1"
          @click="handleSelectType(item, index)"
          @dragstart="handleDragstart(index, $event)"
          @drop.prevent="handleDrop(index, $event)"
        >
          <span class="name">{{ (item.titleDetail || {}).en }}</span>
          <i
            v-if="item.status !== 1"
            class="fa fa-toggle-off"
          />
          <i
            v-else
            class="fa fa-toggle-on toggle-on"
          />
        </li>
      </ul>
      <el-button
        v-if="!isEditState"
        plain
        @click="handleCreateType"
      >
        <i class="fa fa-plus" />
      </el-button>
    </div>
    <div class="button">
      <template v-if="!isEditState">
        <el-button
          class="manage"
          type="primary"
          @click="handleToggleState"
        >
          <i class="fa fa-gears" />
          管理分类
        </el-button>
      </template>
      <template v-else>
        <el-button
          class="save"
          type="primary"
          :loading="isSaving"
          title="保存"
          @click="handleToggleState"
        >
          <i class="fa fa-check" />
        </el-button>
        <el-button
          v-if="isEditState"
          class="cancel"
          @click="handleClose"
        >
          <i class="fa fa-close" />
        </el-button>
      </template>
    </div>
    <el-dialog
      :title="isEditState ? '编辑分类' : '新建分类'"
      :visible.sync="dialogVisible"
      width="600px"
      center
    >
      <el-form
        ref="form"
        :model="formData"
        :rules="rules"
        label-width="80px"
      >
        <el-form-item label="状态">
          <el-switch
            v-model="formData.status"
            :active-value="1"
            :inactive-value="2"
          />
        </el-form-item>
        <el-form-item label="分类封面">
          <UploadImage
            :file-list.sync="coverList"
            :ratio="[200,110]"
            :ratio-max="[200,90]"
          />
        </el-form-item>
        <h4 class="title title-2">
          多语言配置
        </h4>
        <el-tabs
          v-model="currentLang"
          type="card"
        >
          <el-tab-pane
            v-for="(item, index) in langList"
            :key="index"
            :label="item.name"
            :name="item.value"
          >
            <I18NLangFormType
              ref="subForm"
              :key="index"
              :lang="item.value"
              :form="I18NFieldMap[item.value]"
              @validateFail="handleValidateFail"
            />
          </el-tab-pane>
        </el-tabs>
        <!-- <FormItemForTagSearch
          ref="FormItemForTagSearch"
          label="标签"
          prop="tag"
          class="formItem"
          label-width="100px"
          :form="form"
          :rules="rules"
        /> -->
      </el-form>

      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleCancel">取 消</el-button>
        <el-button
          type="primary"
          @click="handleConfirm"
        >确 定</el-button>
        <el-button
          v-if="isEditState"
          type="danger"
          @click="handleDelete"
        >删 除</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { queryTypeOrderList, createADType, updateAllADType, deleteCategoryItemAD } from '@/api/APP_Category'
import { cloneDeep } from 'lodash'
import { mapState } from 'vuex'
import { langList } from '@/utils/selectOptions'
import I18NLangFormType from './I18NLangFormType'
import FormItemForTagSearch from '@/views/game/game-edit/components/FormItemForTagSearch.vue'

/* eslint-disable */
const initLangForm = () => {
  return langList.reduce((maps, item) => {
    maps[item.value] = {}
    return maps
  }, {})
}
export default {
  name: 'TypeManagement',
  components: { I18NLangFormType, FormItemForTagSearch },
  data () {
    return {
      langList,
      isOpen: true,
      loading: false,
      orderList: [],
      orderListSource: [],
      activeIndex: 0,
      dragoverIndex: -1,
      isEditState: false,
      isSaving: false,
      currentType: 1,
      dialogVisible: false,
      formData: {},
      rules: {},
      currentLang: 'en',
      I18NFieldMap: initLangForm(),
      coverList: [],
      form: {
        localTagList: [],
        tag: []
      }
    }
  },
  computed: {
    ...mapState('app', ['appId', 'channel'])
  },
  created () {
    const { style } = this.$route.query
    this.isOpen = Number(style) === 4
    if (this.isOpen) {
      this.queryTypeOrderList()
    }
  },
  activated(){
    const { style } = this.$route.query
    this.isOpen = Number(style) === 4
    if (this.isOpen) {
      this.queryTypeOrderList()
    }
  },
  methods: {
    queryTypeOrderList () {
      const pId = Number(this.$route.query.id)
      this.loading = true
      queryTypeOrderList({
        pId,
        pageNum: 1,
        pageSize: 100,
        appId: this.appId,
        zoneId:this.$route.query.zoneId,
        channel: this.channel
      })
        .then(res => {
          if (res.code === 200) {
            this.orderList = res.data.list || []
            this.orderListSource = cloneDeep(this.orderList)
            if (this.activeIndex !== '' && this.activeIndex <= this.orderList.length - 1) {
              this.$emit('update', this.orderList[this.activeIndex])
            }
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSelectType (item, index) {
      if (!this.isEditState) {
        this.activeIndex = index
        this.$emit('update', item)
      } else {
        this.formData = item
        this.coverList = item.image ? [{ url: item.image, key: item.image }] : []
        this.langList.forEach(lang => {
          const title = item.titleDetail[lang.value]
          this.I18NFieldMap[lang.value] = {
            title
          } || {}
        })
        this.dialogVisible = true
      }
    },

    handleCheckAllForm () {
      const checkAll = (resolve) => {
        Promise.all(this.$refs.subForm.map(item => item.validate()))
          .then((valid) => {
            if (valid) {
              resolve()
            } else {
              this.$message.error('校验未通过, 请检查表单后再试')
            }
          }).catch(err => {
            console.log(err)
          })
      }
      return new Promise(resolve => {
        this.$refs.form.validate()
          .then(valid => {
            if (valid) {
              checkAll(resolve)
            } else {
              this.$message.error('校验未通过, 请检查表单后再试')
            }
          })
      })
    },
    handleCancel () {
      this.dialogVisible = false
      this.coverList = []
    },
    handleConfirm () {
      if (!this.isEditState) {
        this.handleCheckAllForm()
          .then(() => {
            const formData = this.preFormatFormData()
            createADType({
              pId: Number(this.$route.query.id),
              ...formData,
              zoneId: Number(this.$route.query.zoneId),
              appId: this.appId,
              channel: this.channel
            })
              .then(res => {
                if (res.code === 200) {
                  this.queryTypeOrderList()
                  this.dialogVisible = false
                }
              })
          })
      } else {
        this.dialogVisible = false
        this.langList.forEach(lang => {
          const title = this.I18NFieldMap[lang.value].title
          this.formData.titleDetail[lang.value] = title
        })
        this.formData.image = this.coverList?.[0]?.key || ''
        this.coverList = []
        // this.$refs.form.validate(valid => {
        //   if (valid) {
        //     updateADType({
        //       pId: Number(this.$route.query.id),
        //       ...this.formData
        //     })
        //       .then(res => {
        //         if (res.code === 200) {
        //           this.queryTypeOrderList()
        //           this.dialogVisible = false
        //         }
        //       })
        //   }
        // })
      }
    },
    handleDelete () {
      const row = this.formData
      this.$confirm('此操作将删除分类, 是否继续', '提示', { type: 'warning ' })
        .then(() => {
          deleteCategoryItemAD({
            id: row.id
          }).then(res => {
            if (res.code === 200) {
              this.$message.success('删除成功')
              this.queryTypeOrderList()
              this.dialogVisible = false
            }
          })
        })
    },
    handleToggleState () {
      if (!this.isEditState) {
        this.isEditState = true
      } else {
        this.isSaving = true
        const list = this.orderList.map((item, index) => {
          const { id, status, titleDetail , image} = item
          return {
            id,
            status,
            image,
            titleDetail,
            sort: index + 1
          }
        })
        updateAllADType({
          list,
          appId: this.appId,
          channel: this.channel
        }).then(res => {
          if (res.code === 200) {
            this.isEditState = false
            this.queryTypeOrderList()
          }
        })
          .finally(() => {
            this.isSaving = false
          })
      }
    },
    handleCreateType () {
      this.formData = {}
      this.langList.forEach(lang => {
          this.I18NFieldMap[lang.value] = {} 
      })
      this.dialogVisible = true
    },
    handleClose () {
      this.orderList = cloneDeep(this.orderListSource)
      this.activeIndex = 0
      this.$emit('update', this.orderList[0])
      this.isEditState = false
    },
    // 上传前处理表单数据, 以符合接口预期
    preFormatFormData () {
      const formData = cloneDeep(this.formData)
      const I18NFieldMap = this.I18NFieldMap
      formData.titleDetail = Object.keys(I18NFieldMap).reduce((maps, lang) => {
        maps[lang] = I18NFieldMap[lang].title || ''
        return maps
      }, {})
      formData.image = this.coverList?.[0]?.key || ''
      this.coverList = []
      return formData
    },
    // 校验同级表单时, 切换到第一个报错的表单项
    handleValidateFail (lang) {
      if (this.isValidateFail) return
      this.isValidateFail = true
      setTimeout(() => {
        this.isValidateFail = false
        this.currentLang = lang
      })
    },
    handleDragstart (index, event) {
      event.dataTransfer.setData('text/plain', JSON.stringify({ sourceIndex: index }))
    },
    handleDrop (index, event) {
      this.dragoverIndex = -1
      const data = event.dataTransfer.getData('text/plain')
      try {
        const sourceData = JSON.parse(data)
        if (index === sourceData.sourceIndex) return false
        // 暂存activeIndex 所在ID
        const id = this.orderList[this.activeIndex].id
        const temp = this.orderList.splice(sourceData.sourceIndex, 1)
        this.orderList.splice(index, 0, temp[0])
        // 恢复 activeIndex 所在位置
        this.activeIndex = this.orderList.findIndex(item => item.id === id)
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="less">
  .TypeManagement{
    .el-dialog--center .el-dialog__body {
      text-align: initial;
      padding: 10px 25px 0;
    }
    .list{
      overflow: auto;
      width: 98%;
      height: calc(~'100% - 42px');

      /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: transparent;
      }
      /*定义滚动条轨道 内阴影+圆角*/
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      /*定义滑块 内阴影+圆角*/
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgba(144,147,153,.3);
        transition: background-color .3s;
        &:hover{
          background-color: rgba(144,147,153,.5);
        }
      }
      .el-button{
        width: 100%;
        margin-left: 0;
        border-radius: 0;
        margin-top: 2px;
      }
      ul{
        list-style: none;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 0;
        li{
          @h: 43px;
          height: @h;
          line-height: @h;
          text-align: center;
          border: 1px solid #DCDFE6;
          cursor: pointer;
          user-select: none;
          font-size: 14px;
          i{
            color: #13ce66;
          }
          &.active{
            color: @primary;
          }
          &.dragover{
            background: rgba(130, 41, 229, 0.2);
          }
          > span{
            &.name{
              display: inline-block;
              width: 100px;
            }
          }
        }
        li + li{
          border-top: none;
        }
      }
    }
    >.button{
      .el-button--primary{
        &.manage{
          width: 100%;
          height: 42px;
          border-radius: 0;
        }
      }
      .save,
      .cancel{
        width: 46%;
        height: 42px;
        border-radius: 0;
      }
    }
  }
</style>
